import React from "react";

import { Wrap, IconContainer } from "./styled";
import Icon from "../Icon";

interface IButton {
    isAssigned: boolean;
    activeOpacity?: number;
    icon: "dluhopisove-fondy" | "certifikaty" | "dluhopisy" | "akciove-fondy" | "akcie" | "etf";
    isDisabled?: boolean | false;
    onClick?: () => void;
}

const Button: React.FC<IButton> = (props: IButton) => {
    return (
        <Wrap
            isAssigned={props.isAssigned}
            activeOpacity={props.activeOpacity}
            onClick={() => props.onClick && props.onClick()}
            disabled={props.isDisabled}
            isDisabled={props.isDisabled}
        >
            <IconContainer>
                <Icon type={props.icon} color={props.isAssigned ? "#FFFFFF" : "#000046"} />
            </IconContainer>
            {props.children}
        </Wrap>
    );
};

export default Button;
