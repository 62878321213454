import React from "react";
import { Helmet } from "react-helmet";

import GraphPage from "../containers/GraphPage";

const OInstrumentech = () => (
    <>
        <Helmet>
            <title>O instrumentech - Patria Finance</title>
        </Helmet>
        <GraphPage />
    </>
);

export default OInstrumentech;
