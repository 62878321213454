import styled from "styled-components";
import { media } from "../../../../utils/media";

interface IWrap {
    isOpen: boolean;
}

export const Wrap = styled.div<IWrap>`
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    visibility: ${props => (props.isOpen ? "visible" : "hidden")};

    background-color: rgba(0, 0, 70, 0.3);

    position: fixed;
    top: 0;
    left: 0;

    display: ${props => (props.isOpen ? "flex" : "none")};
    align-items: center;
    justify-content: center;
`;

export const Container = styled.div`
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 1px;

    width: 100%;
    padding: 5em;

    display: flex;
    align-items: center;
    flex-direction: column;

    ${media.phone`
        max-width: 600px;
    `}
`;

export const Title = styled.h1`
    color: ${({ theme }) => theme.colors.blue};
    font-family: ${({ theme }) => theme.fonts.verdana};
    font-size: 1.8rem;
    font-weight: bold;
    letter-spacing: 1px;
    text-align: center;
`;

export const Description = styled.p`
    display: block;
    max-width: 600px;
    margin: 1em 0;

    font-size: 1.5rem;
    line-height: 3rem;
    font-family: ${({ theme }) => theme.fonts.openSans};
    text-align: center;

    color: ${({ theme }) => theme.colors.gray};
`;

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    margin: 1.1em 0;

    ${media.phone`
        flex-direction: row;
    `}
`;

interface IButton {
    isSuccess?: boolean;
}

export const Button = styled.a<IButton>`
    border: 1px ${({ theme }) => theme.colors.orange} solid;
    border-radius: 2px;
    cursor: pointer;

    width: 200px;
    margin-bottom: 1em;

    transition: all 100ms linear;

    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.orange};

    font-family: ${({ theme }) => theme.fonts.openSans};
    font-size: 1.4rem;
    text-align: center;
    text-decoration: none;

    padding: 1em 2.8em;

    &:hover {
        color: ${({ theme }) => theme.colors.blue};
        background-color: ${({ theme }) => theme.colors.white};
    }

    ${media.phone`
        margin-right: ${props => (props.isSuccess ? "1em" : "0")};
        margin-bottom: 0;
    `}
`;

export const SecondaryButton = styled.a`
    border: 1px ${({ theme }) => theme.colors.orange} solid;
    border-radius: 2px;
    cursor: pointer;

    transition: all 100ms linear;

    color: ${({ theme }) => theme.colors.blue};
    background-color: ${({ theme }) => theme.colors.white};

    font-family: ${({ theme }) => theme.fonts.openSans};
    font-size: 1.4rem;
    text-align: center;
    text-decoration: none;

    padding: 1em 2.8em;

    &:hover {
        color: ${({ theme }) => theme.colors.white};
        background-color: ${({ theme }) => theme.colors.orange};
    }
`;

interface IIconContainer {
    color?: string;
}

export const IconContainer = styled.div<IIconContainer>`
    width: 75px;
    height: 75px;

    margin-bottom: 1em;

    border-radius: 50%;
    border: 4px solid ${({ color }) => color};

    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Image = styled.img`
    height: 150px;
    margin-bottom: 1em;

    ${media.phone`
        height: 200px;        
    `}
`;
