import React from "react";

import { GraphField, GraphFieldContent, GraphTitle, IconWrap } from "./styled";
import Icon from "../Icon";

interface IGraphItem {
    onClick?: () => void;
    buttonAssigned: boolean;
    isAssigned: boolean;
    assignedOpacity: number;
    icon: "dluhopisove-fondy" | "certifikaty" | "dluhopisy" | "akciove-fondy" | "akcie" | "etf";
    title: string;
    id: number;

    x?: string;
    y?: string;
    tabletX?: string;
    tabletY?: string;
    mobileX?: string;
    mobileY?: string;
}

const GraphItem: React.FC<IGraphItem> = props => {
    return (
        <GraphField
            onClick={() => props.onClick && props.onClick()}
            buttonAssigned={props.buttonAssigned}
            assigned={props.isAssigned}
            assignedOpacity={props.assignedOpacity}
            identifier={props.id}
            x={props.x}
            y={props.y}
            tabletX={props.tabletX}
            tabletY={props.tabletY}
            mobileX={props.mobileX}
            mobileY={props.mobileY}
        >
            <GraphFieldContent>
                <IconWrap>
                    <Icon big type={props.icon} color="#FFFFFF" noMargin />
                </IconWrap>
                <GraphTitle
                    assigned={props.isAssigned}
                    dangerouslySetInnerHTML={{ __html: props.title }}
                />
            </GraphFieldContent>
        </GraphField>
    );
};

export default GraphItem;
