import styled from "styled-components";
import { media } from "../../../../utils/media";

interface IButton {
    isAssigned: boolean;
    isDisabled?: boolean | false;
    activeOpacity?: number;
}

export const Wrap = styled.button<IButton>`
    cursor: ${props => (props.isDisabled ? "not-allowed" : "pointer")};
    padding: 0.8em 1.2em;
    margin: 0.5em;
    border-radius: 2px;
    min-width: 50px;

    width: 45%;
    transition: all 50ms linear;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: ${props =>
        props.isAssigned ? props.theme.colors.blue : props.theme.colors.white};
    border: ${props =>
        props.isAssigned
            ? `2px solid ${props.theme.colors.blue}`
            : `2px dashed ${props.theme.colors.orange}`};
    color: ${props =>
        props.isAssigned ? props.theme.colors.white : props.theme.colors.blue};

    font-size: 1.3rem;
    font-family: ${({ theme }) => theme.fonts.verdana};

    &:focus {
        outline: 0;
    }

    &:hover {
        background: ${({ theme, isAssigned }) =>
        isAssigned ? theme.colors.blue : theme.colors.lightOrange};
    }

    ${media.phone`
        width: auto;
        flex: unset;

        font-size: 1.2rem;
    `}
`;

export const IconContainer = styled.span`
    display: none;

    ${media.phone`
        display: inline;
    `}
`;
