import React from "react";
import { IconWrap } from "./styled";

interface IIcon {
    type: "dluhopisove-fondy" | "certifikaty" | "dluhopisy" | "akciove-fondy" | "akcie" | "etf";
    big?: boolean;
    color: string;
    noMargin?: boolean | false;
}

const Icon: React.FC<IIcon> = ({ type, big, color, noMargin }) => {
    return (
        <IconWrap noMargin={noMargin}>
            {type === "akcie" ? (
                <svg
                    width={big ? 35 : 20}
                    height={big ? 33 : 18}
                    viewBox="0 0 24 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M5 0V2H7V0H5ZM17 0V2H19V0H17ZM16.9922 2.96875L8.96094 9.49414L5.45898 6.99219L0.117188 11.3496L1.38281 12.9004L5.54102 9.50781L9.03906 12.0059L17.0078 5.53125L20.0352 7.92773L23.9121 4.49805L22.5879 3.00195L19.9648 5.32227L16.9922 2.96875ZM5 4V6H7V4H5ZM17 8V10H19V8H17ZM5 12V14H7V12H5ZM17 12V14H19V12H17ZM5 16V18H7V16H5ZM17 16V18H19V16H17ZM0 20V22H24V20H0Z"
                        fill={color}
                    />
                </svg>
            ) : type === "akciove-fondy" ? (
                <svg
                    width={big ? 35 : 20}
                    height={big ? 33 : 18}
                    viewBox="0 0 18 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M6 0C5.73479 2.65169e-05 5.48045 0.105392 5.29292 0.292922C5.10539 0.480453 5.00003 0.734792 5 1V1.5V5H3C2.448 5 2 4.551 2 4C2 3.449 2.448 3 3 3V1C1.346 1 0 2.346 0 4V17C0 18.103 0.897 19 2 19H16C17.103 19 18 18.103 18 17V7C18 5.897 17.103 5 16 5H15V1C15 0.734792 14.8946 0.480453 14.7071 0.292922C14.5195 0.105392 14.2652 2.65169e-05 14 0H6.5H6ZM7.91211 2H12.0879C12.1625 2.21031 12.2831 2.40132 12.4409 2.55911C12.5987 2.7169 12.7897 2.83749 13 2.91211V5H11C11 4.448 10.552 4 10 4C9.448 4 9 4.448 9 5H7V2.91211C7.21031 2.83749 7.40131 2.7169 7.55911 2.55911C7.7169 2.40132 7.83749 2.21031 7.91211 2ZM2 6.81641C2.31439 6.92856 2.64761 7 3 7H6H16V17H2V6.81641ZM13 11C12.448 11 12 11.448 12 12C12 12.552 12.448 13 13 13C13.552 13 14 12.552 14 12C14 11.448 13.552 11 13 11Z"
                        fill={color}
                    />
                </svg>
            ) : type === "certifikaty" ? (
                <svg
                    width={big ? 35 : 20}
                    height={big ? 33 : 18}
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M7.5 0C3.36971 0 0 3.36971 0 7.5C0 11.6303 3.36971 15 7.5 15C9.38577 15 11.1074 14.2913 12.4277 13.1348L13 13.707V15L18 20L20 18L15 13H13.707L13.1348 12.4277C14.2913 11.1074 15 9.38577 15 7.5C15 3.36971 11.6303 0 7.5 0ZM7.23828 2.01172V3.01758C6.92928 3.05658 5.19727 3.3817 5.19727 5.4707C5.19727 8.5187 8.35352 7.61639 8.35352 9.65039C8.35352 10.6684 7.69455 10.6934 7.56055 10.6934C7.43855 10.6934 6.63477 10.7572 6.63477 9.19922H4.91016C4.91016 11.8382 6.82438 12.0489 7.10938 12.0879V12.9805C4.24475 12.7809 2 10.4175 2 7.5C2 4.53894 4.31243 2.1481 7.23828 2.01172ZM8.1582 2.04102C10.8939 2.36433 13 4.67381 13 7.5C13 10.3727 10.8249 12.7129 8.02344 12.9746V12.0879C8.33244 12.0489 10.0762 11.7627 10.0762 9.63867C10.0762 6.71367 6.92088 7.27352 6.92188 5.47852C6.92188 4.45052 7.42037 4.41797 7.60938 4.41797C7.85138 4.41797 8.36523 4.61419 8.36523 5.86719H10.0898C10.0898 3.46919 8.4502 3.09702 8.1582 3.04102V2.04102Z"
                        fill={color}
                    />
                </svg>
            ) : type === "dluhopisove-fondy" ? (
                <svg
                    width={big ? 35 : 20}
                    height={big ? 33 : 18}
                    viewBox="0 0 20 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M2 0C1.87013 0.000434205 1.74061 0.0135168 1.61328 0.0390625C1.6027 0.0411798 1.59255 0.0445889 1.58203 0.046875C1.46239 0.0725617 1.34537 0.109174 1.23242 0.15625C1.22726 0.158426 1.22193 0.159891 1.2168 0.162109C0.85623 0.315563 0.548686 0.571559 0.332362 0.898302C0.116038 1.22504 0.000466277 1.60814 0 2V16C0.000434192 16.1299 0.0135168 16.2594 0.0390625 16.3867C0.0411798 16.3973 0.0445889 16.4074 0.046875 16.418C0.0725617 16.5376 0.109174 16.6546 0.15625 16.7676C0.158426 16.7727 0.159891 16.7781 0.162109 16.7832C0.315563 17.1438 0.571559 17.4513 0.898302 17.6676C1.22504 17.884 1.60814 17.9995 2 18H18C18.1299 17.9996 18.2594 17.9865 18.3867 17.9609C18.3973 17.9588 18.4074 17.9554 18.418 17.9531C18.8652 17.8575 19.2662 17.6115 19.5541 17.2561C19.842 16.9007 19.9994 16.4574 20 16V2C19.9996 1.87013 19.9865 1.74061 19.9609 1.61328C19.9588 1.60269 19.9554 1.59257 19.9531 1.58203C19.8575 1.13475 19.6115 0.733752 19.2561 0.44587C18.9007 0.157988 18.4574 0.000620508 18 0H2ZM4 2H16C16 2.53043 16.2107 3.03914 16.5858 3.41421C16.9609 3.78929 17.4696 4 18 4V14C17.4696 14 16.9609 14.2107 16.5858 14.5858C16.2107 14.9609 16 15.4696 16 16H4C4 15.4696 3.78929 14.9609 3.41421 14.5858C3.03914 14.2107 2.53043 14 2 14V4C2.53043 4 3.03914 3.78929 3.41421 3.41421C3.78929 3.03914 4 2.53043 4 2ZM9.71484 3V4.0293C9.37584 4.0723 7.47656 4.42875 7.47656 6.71875C7.47656 10.0597 10.9355 9.07078 10.9355 11.3008C10.9355 12.4168 10.2154 12.4434 10.0684 12.4434C9.94036 12.4434 9.13641 12.489 9.06641 11H7.17188C7.26687 13.702 9.26727 13.9287 9.57227 13.9707V15H10.5742V13.9707C10.9132 13.9277 12.8242 13.6141 12.8242 11.2871C12.8242 8.08111 9.36523 8.69356 9.36523 6.72656C9.36523 5.59956 9.98709 5.56445 10.1211 5.56445C10.3561 5.56445 10.8945 5.775 10.9395 7H12.8242C12.7492 4.523 11.0357 4.11664 10.7227 4.05664V3H9.71484ZM4 8V10H6V8H4ZM14 8V10H16V8H14Z"
                        fill={color}
                    />
                </svg>
            ) : type === "dluhopisy" ? (
                <svg
                    width={big ? 35 : 20}
                    height={big ? 33 : 18}
                    viewBox="0 0 22 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M11.5 0C10.119 0 9 1.119 9 2.5C9 3.881 10.119 5 11.5 5C11.6733 5 11.8425 4.98276 12.0059 4.94922C12.0056 4.96657 12 4.98259 12 5C12 7.209 13.791 9 16 9C18.209 9 20 7.209 20 5C20 2.791 18.209 1 16 1C15.2019 1 14.4621 1.23976 13.8379 1.64258C13.4869 0.68639 12.5781 0 11.5 0ZM11.5 1.75C11.914 1.75 12.25 2.086 12.25 2.5C12.25 2.914 11.914 3.25 11.5 3.25C11.086 3.25 10.75 2.914 10.75 2.5C10.75 2.086 11.086 1.75 11.5 1.75ZM16 3C17.105 3 18 3.895 18 5C18 6.105 17.105 7 16 7C14.895 7 14 6.105 14 5C14 3.895 14.895 3 16 3ZM5 8C4.709 8 4.43397 8.05386 4.16797 8.13086L0 9.10742V16.3086L4 15L12.1562 17.8535C12.4213 17.9465 12.6967 17.9922 12.9707 17.9922C13.3727 17.9922 13.7728 17.8952 14.1348 17.7012L21.4629 13.7832L20.2852 11.2676L13.1211 14.8809L12.4883 14.6953L13.0078 13.4277C13.4628 12.4127 13.0024 11.2204 11.9824 10.7754L6.11523 8.2207C5.76923 8.0817 5.395 8 5 8ZM5 10C5.114 10 5.23161 10.0224 5.34961 10.0664L11.1816 12.6074L11.168 12.6387L11.1543 12.6699L10.6367 13.9375L10.1855 15.0449L4.66016 13.1113L4.02148 12.8887L3.37891 13.0977L2 13.5508V10.6934L4.62305 10.0781L4.67578 10.0664L4.72461 10.0527C4.84461 10.0187 4.937 10 5 10Z"
                        fill={color}
                    />
                </svg>
            ) : type === "etf" ? (
                <svg
                    width={big ? 35 : 20}
                    height={big ? 33 : 18}
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M9.95117 0L5.95117 4H9V6H11V4H13.9512L9.95117 0ZM1 8C0.448 8 0 8.448 0 9V19C0 19.552 0.448 20 1 20H19C19.552 20 20 19.552 20 19V9C20 8.448 19.552 8 19 8H1ZM2.91211 10H17.0879C17.1625 10.2103 17.2831 10.4013 17.4409 10.5591C17.5987 10.7169 17.7897 10.8375 18 10.9121V17.0879C17.7897 17.1625 17.5987 17.2831 17.4409 17.4409C17.2831 17.5987 17.1625 17.7897 17.0879 18H2.91211C2.83749 17.7897 2.7169 17.5987 2.55911 17.4409C2.40132 17.2831 2.21031 17.1625 2 17.0879V10.9121C2.21031 10.8375 2.40132 10.7169 2.55911 10.5591C2.7169 10.4013 2.83749 10.2103 2.91211 10ZM10 11C9.20435 11 8.44129 11.3161 7.87868 11.8787C7.31607 12.4413 7 13.2044 7 14C7 14.7956 7.31607 15.5587 7.87868 16.1213C8.44129 16.6839 9.20435 17 10 17C10.7956 17 11.5587 16.6839 12.1213 16.1213C12.6839 15.5587 13 14.7956 13 14C13 13.2044 12.6839 12.4413 12.1213 11.8787C11.5587 11.3161 10.7956 11 10 11ZM5 13C4.448 13 4 13.447 4 14C4 14.553 4.448 15 5 15C5.552 15 6 14.553 6 14C6 13.447 5.552 13 5 13ZM15 13C14.448 13 14 13.447 14 14C14 14.553 14.448 15 15 15C15.552 15 16 14.553 16 14C16 13.447 15.552 13 15 13Z"
                        fill={color}
                    />
                </svg>
            ) : null}
        </IconWrap>
    );
};

export default Icon;
