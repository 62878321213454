import styled, { css, keyframes } from "styled-components";
import { media } from "../../../../utils/media";

interface IGraphField {
    buttonAssigned: boolean;
    assigned?: boolean;
    assignedOpacity: number;
    identifier: number;
    x?: string;
    y?: string;
    tabletX?: string;
    tabletY?: string;
    mobileX?: string;
    mobileY?: string;
}

const glowKeyframes = keyframes`
    0% {
        border-color: #F59100;
    }
    40% {
        border-color: #ffedd4;
    }
    80% {
        border-color: #F59100;
    }
`;

const glow = css`
    animation: ${glowKeyframes} 1100ms linear infinite;
`;

export const GraphField = styled.div<IGraphField>`
    background-color: ${props =>
        props.assigned ? props.theme.colors.blue : props.theme.colors.white};
    border: ${props =>
        props.assigned
            ? `2px solid ${props.theme.colors.blue}`
            : `2px dashed ${props.theme.colors.orange}`};
    opacity: ${props => (props.assigned ? props.assignedOpacity : 1)};
    cursor: pointer;
    display: inline-block;

    ${props => (props.assigned ? null : props.buttonAssigned ? glow : null)};

    width: 30px;
    height: 30px;

    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    transform: ${props => `translate(${props.mobileX}, ${props.mobileY}) rotate(45deg) scale(1.4)`};

    ${media.phone`
        width: 130px;
        height: 130px; 
        
        transform: ${props =>
            `translate(${props.tabletX}, ${props.tabletY}) rotate(45deg) scale(0.75)`};

            border: ${props =>
                props.assigned
                    ? `4px solid ${props.theme.colors.blue}`
                    : `4px dashed ${props.theme.colors.orange}`};
    `}

    ${media.desktop` 
        transform: ${props => `translate(${props.x},${props.y}) rotate(45deg) scale(0.85)`};
    `}
`;

export const GraphFieldContent = styled.div`
    transform: rotate(-45deg) scale(0.6);

    height: 33px;
    width: 170px;

    position: absolute;
    left: -43px;
    top: -32px;

    display: flex;
    align-items: center;
    flex-direction: row;

    ${media.phone`
        position: static;

        flex-direction: column;
        height: auto;
        width: auto;
        transform: rotate(-45deg) scale(1);
    `}
`;

export const IconWrap = styled.div`
    transform: scale(0.8);
    ${media.phone`
    transform: scale(1);
    `}
`;

interface IGraphTitle {
    assigned: boolean;
}

export const GraphTitle = styled.h2<IGraphTitle>`
    margin-top: 0.7em;
    visibility: ${props => (props.assigned ? "visible" : "hidden")};
    color: ${props => (props.assigned ? props.theme.colors.blue : props.theme.colors.white)};
    font-family: ${({ theme }) => theme.fonts.verdana};
    text-align: center;
    font-size: 1.5rem;
    margin-left: 2em;

    ${media.phone`
        margin-left: 0;
        color: ${({ theme }) => theme.colors.white};
        font-size: 1.6rem;
        line-height: 2.3rem;
    `}
`;
