import React, { useState, useEffect, useRef } from "react";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

import {
    Section,
    Title,
    Description,
    ButtonLine,
    BottomButtonLine,
    GraphItemsContainer,
    MobileButtonContainer,
    XAxis,
    YAxis,
    Arrow,
    RewardButton,
} from "./styled";
import Button from "./Button";
import GraphItem from "./GraphItem";
import {
    graphItems,
    correctPopup,
    incorrectPopup,
    infoPopup,
    completePopup,
} from "../../data/graph";
import Popup from "./Popup";
import { arraysEqual } from "../../../utils/util";
import book from "../../images/book.png";

const defaultAssignedButtons = [false, false, false, false, false, false];
const fullAssignedButtons = [true, true, true, true, true, true];

const defaultPopupState = { isComplete: false, title: "", text: "", icon: "" };

const GraphSection = () => {
    const [activeButton, setActiveButton] = useState(undefined);
    const [assignedButtons, setAssignedButtons] = useState([
        ...defaultAssignedButtons,
    ]);

    const [isPopupOpen, setPopupOpen] = useState(false);
    const [popupState, setPopupState] = useState(defaultPopupState);

    const popupRef = useRef(null);

    useEffect(() => {
        setActiveButton(undefined);

        if (sessionStorage.getItem("graph") !== null) {
            const storedState = JSON.parse(sessionStorage.getItem("graph"));
            setAssignedButtons([...storedState]);
        }
    }, []);

    const closePopup = () => {
        setPopupOpen(false);
        enableBodyScroll(popupRef.current);
    };

    const openCongratsPopup = () => {
        setPopupState(completePopup);
        setPopupOpen(true);
        disableBodyScroll(popupRef.current);
    };

    const isGraphFull = arraysEqual(assignedButtons, fullAssignedButtons);

    const assignButtonToGraph = (id: number) => {
        if (activeButton !== undefined) {
            if (id === activeButton) {
                const newAssignedButtons = [...assignedButtons];
                newAssignedButtons[id] = true;
                setAssignedButtons(newAssignedButtons);
                setActiveButton(undefined);

                sessionStorage.setItem(
                    "graph",
                    JSON.stringify([...newAssignedButtons])
                );

                if (arraysEqual(newAssignedButtons, fullAssignedButtons)) {
                    openCongratsPopup();
                } else {
                    setPopupState(
                        correctPopup.find(popup => popup.id === activeButton)
                    );
                    setPopupOpen(true);
                    disableBodyScroll(popupRef.current);
                }
            } else {
                setPopupState(
                    incorrectPopup.find(popup => popup.id === activeButton)
                );
                setPopupOpen(true);
                disableBodyScroll(popupRef.current);
                setActiveButton(undefined);
            }
        }
        if (assignedButtons[id] === true) {
            setPopupState(infoPopup.find(popup => popup.id === id));
            setPopupOpen(true);
            disableBodyScroll(popupRef.current);
        }
    };

    const toggleButton = (id: number) => {
        if (activeButton === id) {
            setActiveButton(undefined);
        } else {
            setActiveButton(id);
        }
    };

    const isCongratsPopup = popupState.isComplete;

    return (
        <Section id="graph">
            <Title data-aos="fade-up" data-aos-delay="200">
                Sestavte graf
            </Title>
            <Description data-aos="fade-up" data-aos-delay="300">
                Poskládejte zobrazené investiční nástroje podle rizikovosti. Po
                zvolení investičního nástroje klikněte na jemu odpovídající
                kombinaci výnosu a rizika na grafu pod nástroji.
            </Description>
            <ButtonLine data-aos="fade-up" data-aos-delay="300">
                <Button
                    isAssigned={assignedButtons[1] ? true : activeButton === 1}
                    isDisabled={assignedButtons[1]}
                    activeOpacity={0.7}
                    icon="dluhopisove-fondy"
                    onClick={() => toggleButton(1)}
                >
                    Dluhopisové fondy a fondy peněžního trhu
                </Button>
                <Button
                    isAssigned={assignedButtons[4] ? true : activeButton === 4}
                    isDisabled={assignedButtons[4]}
                    activeOpacity={0.5}
                    icon="certifikaty"
                    onClick={() => toggleButton(4)}
                >
                    Investiční certifikáty a warranty
                </Button>
            </ButtonLine>
            <BottomButtonLine data-aos="fade-up" data-aos-delay="300">
                <MobileButtonContainer>
                    <Button
                        isAssigned={
                            assignedButtons[0] ? true : activeButton === 0
                        }
                        isDisabled={assignedButtons[0]}
                        activeOpacity={0.5}
                        icon="dluhopisy"
                        onClick={() => toggleButton(0)}
                    >
                        Korporátní dluhopisy
                    </Button>
                    <Button
                        isAssigned={
                            assignedButtons[2] ? true : activeButton === 2
                        }
                        isDisabled={assignedButtons[2]}
                        activeOpacity={0.8}
                        icon="akciove-fondy"
                        onClick={() => toggleButton(2)}
                    >
                        Akciové fondy
                    </Button>
                </MobileButtonContainer>
                <MobileButtonContainer>
                    <Button
                        isAssigned={
                            assignedButtons[3] ? true : activeButton === 3
                        }
                        isDisabled={assignedButtons[3]}
                        activeOpacity={1}
                        icon="akcie"
                        onClick={() => toggleButton(3)}
                    >
                        Akcie
                    </Button>
                    <Button
                        isAssigned={
                            assignedButtons[5] ? true : activeButton === 5
                        }
                        isDisabled={assignedButtons[5]}
                        activeOpacity={1}
                        icon="etf"
                        onClick={() => toggleButton(5)}
                    >
                        ETF
                    </Button>
                </MobileButtonContainer>
            </BottomButtonLine>
            {isGraphFull ? (
                <RewardButton
                    onClick={() => openCongratsPopup()}
                    data-aos="fade-up"
                    data-aos-delay="300"
                >
                    Přejít zpět k odměně
                </RewardButton>
            ) : null}
            <GraphItemsContainer data-aos="fade-up" data-aos-delay="300">
                <XAxis />
                <Arrow direction="up" />
                <YAxis />
                <Arrow direction="right" />
                {graphItems.map(item => (
                    <GraphItem
                        id={item.id}
                        key={item.title}
                        onClick={() => assignButtonToGraph(item.id)}
                        buttonAssigned={activeButton !== undefined}
                        isAssigned={assignedButtons[item.id]}
                        assignedOpacity={item.assignedOpacity}
                        icon={item.icon}
                        title={item.title}
                        x={item.x}
                        y={item.y}
                        tabletX={item.tabletX}
                        tabletY={item.tabletY}
                        mobileX={item.mobileX}
                        mobileY={item.mobileY}
                    />
                ))}
            </GraphItemsContainer>
            <Popup
                reference={popupRef}
                isSuccess={false}
                visible={isPopupOpen}
                title={popupState.title}
                close={() => closePopup()}
                primaryButtonTitle={
                    isCongratsPopup
                        ? popupState.primaryButtonText
                        : "Pokračovat"
                }
                primaryButtonLink={
                    isCongratsPopup ? popupState.primaryButtonLink : null
                }
                primaryOnClick={() => closePopup()}
                secondaryButtonTitle={
                    isCongratsPopup ? popupState.secondaryButtonText : null
                }
                secondaryButtonLink={
                    isCongratsPopup ? popupState.secondaryButtonLink : null
                }
                secondaryOnClick={() => closePopup()}
                linkTitle={isCongratsPopup ? popupState.linkText : null}
                bottomLinkLink="/slovnik"
                linkOnClick={() => closePopup()}
                imgSource={isCongratsPopup ? book : null}
                iconName={isCongratsPopup ? null : popupState.icon}
                circleColor={
                    popupState.icon === "correct"
                        ? "#46E08C"
                        : popupState.icon === "incorrect"
                        ? "#F63838"
                        : "#000046"
                }
            >
                {isPopupOpen && popupState.text}
            </Popup>
        </Section>
    );
};

export default GraphSection;
