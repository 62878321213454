import React, { useRef } from "react";
import { Link } from "gatsby";

import {
    Wrap,
    Container,
    Title,
    Description,
    Button,
    IconContainer,
    SecondaryButton,
    ButtonContainer,
    Image,
} from "./styled";
import UnderlineLink from "./UnderlineLink";
import BigIcon from "../../Dictionary/DictionaryMenu/icons/BigIcon";
import { enableBodyScroll } from "body-scroll-lock";

interface IPopup {
    visible: boolean;
    isSuccess?: boolean;
    imgSource?: string;
    iconName?:
        | "correct"
        | "incorrect"
        | "dluhopisove-fondy"
        | "certifikaty"
        | "dluhopisy"
        | "akciove-fondy"
        | "akcie"
        | "etf";
    close: () => void;
    title: string;
    primaryButtonTitle: string;
    secondaryButtonTitle?: string;
    linkTitle?: string;
    primaryOnClick?: () => void;
    secondaryOnClick?: () => void;
    linkOnClick?: () => void;
    primaryButtonLink?: string | "";
    secondaryButtonLink?: string | "";
    bottomLinkLink?: string | "";
    reference?: any;
    circleColor?: string;
}

const Popup: React.FC<IPopup> = props => {
    const popup = useRef(null);

    const closeOnOutsideClick = (e: any, ref: any) => {
        if (ref.current && !ref.current.contains(e.target)) {
            props.close();
        }
    };

    const primaryButtonClick = () => {
        setTimeout(() => {
            props.primaryOnClick && props.primaryOnClick();
        }, 50);
    };

    const secondaryButtonClick = () => {
        setTimeout(() => {
            props.secondaryOnClick && props.secondaryOnClick();
        }, 50);
    };

    return (
        <Wrap
            isOpen={props.visible}
            ref={props.reference}
            onClick={e => closeOnOutsideClick(e, popup)}
        >
            <Container ref={popup}>
                {props.imgSource ? (
                    <Image src={props.imgSource} alt={props.title} />
                ) : (
                    <IconContainer color={props.circleColor}>
                        <BigIcon type={props.iconName} />
                    </IconContainer>
                )}
                <Title>{props.title}</Title>
                <Description>{props.children}</Description>
                <ButtonContainer>
                    <Button
                        href={props.primaryButtonLink}
                        onClick={() => primaryButtonClick()}
                        isSuccess={props.isSuccess}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {props.primaryButtonTitle}
                    </Button>

                    {props.secondaryButtonTitle ? (
                        <SecondaryButton
                            href={props.secondaryButtonLink}
                            onClick={() => secondaryButtonClick()}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {props.secondaryButtonTitle}
                        </SecondaryButton>
                    ) : null}
                </ButtonContainer>
                {props.linkTitle ? (
                    <Link
                        to={props.bottomLinkLink}
                        style={{ textDecoration: "none", marginTop: "1.7em" }}
                        onClick={() => enableBodyScroll(popup.current)}
                    >
                        <UnderlineLink
                            onClick={() =>
                                props.linkOnClick && props.linkOnClick()
                            }
                        >
                            {props.linkTitle}
                        </UnderlineLink>
                    </Link>
                ) : null}
            </Container>
        </Wrap>
    );
};

export default Popup;
