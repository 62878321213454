export const graphItems = [
    {
        id: 1,
        assignedOpacity: 0.5,
        icon: "dluhopisove-fondy",
        title: "Dluhopisové <br/> fondy",
        x: "40px",
        y: "-30%",
        tabletX: "10%",
        tabletY: "-15%",
        mobileX: "90%",
        mobileY: "-30px",
    },
    {
        id: 2,
        assignedOpacity: 0.5,
        icon: "akciove-fondy",
        title: "Akciové <br/> fondy",
        x: "60px",
        y: "-80%",
        tabletX: "10%",
        tabletY: "-60%",
        mobileX: "110%",
        mobileY: "-80px",
    },
    {
        id: 0,
        assignedOpacity: 0.8,
        icon: "dluhopisy",
        title: "Korporátní <br/> dluhopisy",
        x: "85px",
        y: "-130%",
        tabletX: "15%",
        tabletY: "-105%",
        mobileX: "120%",
        mobileY: "-135px",
    },
    {
        id: 3,
        assignedOpacity: 0.8,
        icon: "akcie",
        title: "Akcie",
        x: "115px",
        y: "-185%",
        tabletX: "17%",
        tabletY: "-150%",
        mobileX: "120%",
        mobileY: "-190px",
    },
    {
        id: 5,
        assignedOpacity: 1,
        icon: "etf",
        title: "ETF",
        x: "150px",
        y: "-240%",
        tabletX: "15%",
        tabletY: "-200%",
        mobileX: "110%",
        mobileY: "-250px",
    },
    {
        id: 4,
        assignedOpacity: 1,
        icon: "certifikaty",
        title: "Deriváty",
        x: "185px",
        y: "-295%",
        tabletX: "8%",
        tabletY: "-250%",
        mobileX: "100%",
        mobileY: "-310px",
    },
];

export const correctPopup = [
    {
        id: 0,
        title: "Správná odpověď!",
        text:
            "Korporátní dluhopisy -  V případě korporátních dluhopisů se jedná o závazek, v němž dlužník slibuje splatit věřiteli k předem stanovenému datu vypůjčenou částku společně s výnosem. Vzhledem k riziku nesplacení jistiny emitentem se jedná o rizikovější instrument.",
        icon: "correct",
    },
    {
        id: 1,
        title: "Správná odpověď!",
        text:
            "Dluhopisové fondy a fondy peněžního trhu – Nízká volatilita dluhopisů, dluhopisových fondů a fondů peněžního trhu je spojena s nízkým potenciálním výnosem a také rizikem.",
        icon: "correct",
    },
    {
        id: 2,
        title: "Správná odpověď!",
        text:
            "Akciové fondy – Jedná se o investice s vyšší volatilitou. Vzhledem k diverzifikaci portfolia fondů představují investice do akciových fondů nižší riziko než investice do jednotlivých akcií či dluhopisů.",
        icon: "correct",
    },
    {
        id: 3,
        title: "Správná odpověď!",
        text:
            "Akcie – Správně provedený výběr akcií může přinést dvojí zisk: růst investovaného kapitálu a příjem z vyplácených dividend.  Akcie řadíme mezi rizikovější instrumenty z důvodu jejich vyšší volatility.",
        icon: "correct",
    },
    {
        id: 4,
        title: "Správná odpověď!",
        text:
            "Investiční certifikáty a warranty – Instrumenty, jejichž podstatou je forma termínového obchodu a jejichž hodnota se odvozuje od hodnoty podkladového aktiva. Lze je využít jako prostředek k zajištění portfolia i jako nástroj ke spekulaci. Mohou vykazovat vysokou míru rizika.",
        icon: "correct",
    },
    {
        id: 5,
        title: "Správná odpověď!",
        text:
            "ETF – Pomocí ETF lze investice rychle, jednoduše a levně diverzifikovat. ETF mohou být i páková, ta pak patří mezi rizikovější instrumenty.",
        icon: "correct",
    },
];

export const incorrectPopup = [
    {
        id: 0,
        title: "Zkuste to ještě jednou!",
        text:
            "Korporátní dluhopisy -  V případě korporátních dluhopisů se jedná o závazek, v němž dlužník slibuje splatit věřiteli k předem stanovenému datu vypůjčenou částku společně s výnosem. Vzhledem k riziku nesplacení jistiny emitentem se jedná o rizikovější instrument.",
        icon: "incorrect",
    },
    {
        id: 1,
        title: "Zkuste to ještě jednou!",
        text:
            "Dluhopisové fondy a fondy peněžního trhu – Nízká volatilita dluhopisů, dluhopisových fondů a fondů peněžního trhu je spojena s nízkým potenciálním výnosem a také rizikem.",
        icon: "incorrect",
    },
    {
        id: 2,
        title: "Zkuste to ještě jednou!",
        text:
            "Akciové fondy – Jedná se o investice s vyšší volatilitou. Vzhledem k diverzifikaci portfolia fondů představují investice do akciových fondů nižší riziko než investice do jednotlivých akcií či dluhopisů.",
        icon: "incorrect",
    },
    {
        id: 3,
        title: "Zkuste to ještě jednou!",
        text:
            "Akcie – Správně provedený výběr akcií může přinést dvojí zisk: růst investovaného kapitálu a příjem z vyplácených dividend.  Akcie řadíme mezi rizikovější instrumenty z důvodu jejich vyšší volatility.",
        icon: "incorrect",
    },
    {
        id: 4,
        title: "Zkuste to ještě jednou!",
        text:
            "Investiční certifikáty a warranty – Instrumenty, jejichž podstatou je forma termínového obchodu a jejichž hodnota se odvozuje od hodnoty podkladového aktiva. Lze je využít jako prostředek k zajištění portfolia i jako nástroj ke spekulaci. Mohou vykazovat vysokou míru rizika.",
        icon: "incorrect",
    },
    {
        id: 5,
        title: "Zkuste to ještě jednou!",
        text:
            "ETF – Pomocí ETF lze investice rychle, jednoduše a levně diverzifikovat. ETF mohou být i páková, ta pak patří mezi rizikovější instrumenty.",
        icon: "incorrect",
    },
];

export const infoPopup = [
    {
        id: 0,
        title: "Korporátní dluhopisy",
        text:
            "V případě korporátních dluhopisů se jedná o závazek, v němž dlužník slibuje splatit věřiteli k předem stanovenému datu vypůjčenou částku společně s výnosem. Vzhledem k riziku nesplacení jistiny emitentem se jedná o rizikovější instrument.",
        icon: "dluhopisy",
    },
    {
        id: 1,
        title: "Dluhopisové fondy a fondy peněžního trhu",
        text:
            "Nízká volatilita dluhopisů, dluhopisových fondů a fondů peněžního trhu je spojena s nízkým potenciálním výnosem a také rizikem.",
        icon: "dluhopisove-fondy",
    },
    {
        id: 2,
        title: "Akciové fondy",
        text:
            "Jedná se o investice s vyšší volatilitou. Vzhledem k diverzifikaci portfolia fondů představují investice do akciových fondů nižší riziko než investice do jednotlivých akcií či dluhopisů.",
        icon: "akciove-fondy",
    },
    {
        id: 3,
        title: "Akcie",
        text:
            "Správně provedený výběr akcií může přinést dvojí zisk: růst investovaného kapitálu a příjem z vyplácených dividend.  Akcie řadíme mezi rizikovější instrumenty z důvodu jejich vyšší volatility.",
        icon: "akcie",
    },
    {
        id: 4,
        title: "Investiční certifikáty a warranty",
        text:
            "Instrumenty, jejichž podstatou je forma termínového obchodu a jejichž hodnota se odvozuje od hodnoty podkladového aktiva. Lze je využít jako prostředek k zajištění portfolia i jako nástroj ke spekulaci. Mohou vykazovat vysokou míru rizika.",
        icon: "certifikaty",
    },
    {
        id: 5,
        title: "ETF",
        text:
            "Pomocí ETF lze investice rychle, jednoduše a levně diverzifikovat. ETF mohou být i pákové ETF, ty pak patří mezi rizikovější instrumenty.",
        icon: "etf",
    },
];

export const completePopup = {
    isComplete: true,
    title: "Gratulujeme",
    text:
        "Pro detailnější informace k instrumentům a investování navštivte naši Školu investora plnou webinářů a e-booků ZDARMA.",
    primaryButtonText: "Škola investora",
    linkText: "Zjistěte víc o instrumentech",
    primaryButtonLink:
        "https://www.patria-finance.cz/vzdelavani/zaciname-s-investovanim/skola-investora",
    linkLink: "/slovnik",
};
