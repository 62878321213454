import styled from "styled-components";
import { media } from "../../../../../utils/media";

export const Link = styled.a`
    background-color: transparent;
    text-decoration: none;
    padding: 0;
    cursor: pointer;

    max-width: 50%;
    text-transform: uppercase;
    padding-bottom: 12px;
    color: ${({ theme }) => theme.colors.orange};
    border-bottom: 2px solid ${({ theme }) => theme.colors.orange};
    transition-duration: 0.3s;

    font-family: ${({ theme }) => theme.fonts.verdana};
    line-height: 1.3rem;
    font-size: 1rem;
    letter-spacing: 0.15rem;
    :hover {
        border-bottom: 2px solid ${({ theme }) => theme.colors.blue};
    }

    ${media.phone`
        font-size: 1.2rem;
    `}
`;
