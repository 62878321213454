import React from "react";

import { Link } from "./styled";

interface IUnderlineLink {
    styles: any;
    onClick: () => void;
}

const UnderlineLink: React.FC<IUnderlineLink> = props => {
    return (
        <Link onClick={() => props.onClick()} style={{ ...props.styles }}>
            {props.children}
        </Link>
    );
};

export default UnderlineLink;
