import React from "react";
import MaxWidthContainer from "../../components/MaxWidth";
import AboutSection from "../../components/AboutSection";
import BigImageSection from "../../components/BigImageSection";
import GraphSection from "../../components/GraphSection";

import about from "../../images/about-building.png";
import computer from "../../images/dictionary-computer.png";
import charts from "../../images/dictionary-charts.png";

const GraphPage = () => {
    return (
        <MaxWidthContainer>
            <AboutSection
                id="about"
                paragraph="Víte, jakou kombinaci výnosu a rizika mají jednotlivé instrumenty?"
                title="Jak si stojí Vaše znalosti v oblasti investic?"
                underlayColor="#F59100"
                imageUrl={about}
            />
            <GraphSection id="graph" />
            <BigImageSection
                id="bigImage"
                title="Patria Slovník"
                subTitle="Learn More"
                underlayColor="#F59100"
                smallImageUrl={charts}
                bigImageUrl={computer}
            />
        </MaxWidthContainer>
    );
};

export default GraphPage;
