import styled from "styled-components";
import { media } from "../../../utils/media";

export const Section = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 110px 30px 100px;

    ${media.tablet`
        margin: 150px 120px;
    `}

    ${media.desktop`
      margin: 100px auto 200px;
      height: auto;
      max-width: 1560px;
      padding: 0 180px;
      box-sizing: content-box;
    `}
`;

export const Title = styled.h1`
    margin: 0;
    font-size: 2.5em;
    line-height: 4rem;
    color: ${({ theme }) => theme.colors.blue};
    font-family: ${({ theme }) => theme.fonts.verdana};
    position: relative;

    ${media.tablet`
        font-size: 3.5em;
        line-height: 4.2rem;
    `}
`;

export const Description = styled.p`
    display: block;
    max-width: 650px;
    margin: 1em 0;

    font-size: 1.5rem;
    line-height: 3rem;
    font-family: ${({ theme }) => theme.fonts.openSans};
    text-align: center;

    color: ${({ theme }) => theme.colors.gray};
`;

export const ButtonLine = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    z-index: 4;

    ${media.phone`
        width: auto;
        justify-content: flex-start;
    `}
`;

export const BottomButtonLine = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    z-index: 4;

    ${media.phone`
        width: auto;
        flex-direction: row;
    `}
`;

export const GraphItemsContainer = styled.div`
    position: relative;
    margin-top: 3em;

    height: 430px;
    width: 270px;

    display: flex;
    flex-direction: row;
    align-items: flex-end;

    ${media.phone`
        margin-top: 7em;
        height: 480px;
        width: 100%;
    `}

    ${media.desktop`    
        height: 530px;
        width: 980px;
    `}
`;

export const XAxis = styled.div`
    width: 100%;
    height: 2px;

    position: absolute;
    bottom: 0;
    left: 0;

    background-color: ${({ theme }) => theme.colors.orange};

    &::after {
        content: "Riziko ztráty";
        width: 100%;
        display: flex;
        justify-content: flex-end;

        color: ${({ theme }) => theme.colors.blue};
        font-family: ${({ theme }) => theme.fonts.verdana};
        font-size: 1.8rem;
        font-weight: bold;

        margin: 1.3em 0 0 -1.3em;
    }
`;

export const YAxis = styled.div`
    width: 2px;
    height: 100%;

    position: absolute;
    top: 0;
    left: 0;

    background-color: ${({ theme }) => theme.colors.orange};

    &::after {
        content: "Možný výnos";
        width: 140px;
        margin-top: 2em;
        display: flex;
        justify-content: flex-end;

        color: ${({ theme }) => theme.colors.blue};
        font-family: ${({ theme }) => theme.fonts.verdana};
        font-size: 1.8rem;
        font-weight: bold;

        ${media.phone`
            width: 160px;
        `}
    }
`;

interface IArrow {
    direction: "up" | "right";
}

export const Arrow = styled.i<IArrow>`
    display: inline-block;
    font-style: normal;
    position: absolute;

    ${props =>
        props.direction === "up"
            ? `left: -0.3em;
                top: 0;`
            : `
            bottom: -0.3em;
                right: 0;
            `}

    width: 0.8em;
    height: 0.8em;
    border-right: 2px solid black;
    border-top: 2px solid black;
    border-color: ${({ theme }) => theme.colors.orange};
    transform: rotate(
        ${props => (props.direction === "up" ? "-45deg" : "45deg")}
    );
`;

export const MobileButtonContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-around;

    ${media.phone`
        justify-content: flex-start;
        width: auto;
    `}
`;

export const RewardButton = styled.button`
    visibility: visible;
    display: block;

    font-size: 1.2rem;
    line-height: 1.7rem;
    font-weight: 500;
    letter-spacing: 1px;
    font-family: ${({ theme }) => theme.fonts.openSans};
    text-decoration: none;
    cursor: pointer;

    padding: 1.2em 2.9em;
    margin: 2em 0 0;
    transition: border 100ms linear;

    border: 1px solid ${({ theme }) => theme.colors.lightOrange};
    color: ${({ theme }) => theme.colors.orange};
    background-color: ${({ theme }) => theme.colors.lightOrange};

    &:hover {
        border: 1px solid ${({ theme }) => theme.colors.orange};
    }
`;
